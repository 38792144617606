import cn from 'classnames';
import styles from './SearchNotFound.module.scss';

type SearchNotFoundProps = {
  query: string;
  className?: string;
};

export function SearchNotFound({ query, className }: SearchNotFoundProps) {
  const wrapperClassName = cn(styles.wrapper, className);

  return (
    <div className={wrapperClassName}>
      <img loading="lazy" src="/svg/no-results.svg" alt="no-results" />
      <p>
        По запросу «<span className={styles.highlight}>{query}</span>»
        результатов не найдено
      </p>
    </div>
  );
}
